import { ANALYTICS_MODULE } from "constants/index";
import React from "react";
import { Redirect } from "react-router-dom";
import { useVideoUploadByIdPublicQuery } from "services/graphql";
import { UserVideoPost } from "app/components/UserVideos/UserVideoPost";

interface Props {
  userVideoId: string;
  fromModule: string;
}

export function VideoPost({
  userVideoId,
  fromModule = ANALYTICS_MODULE.user_video_modal,
}: Props) {
  const {
    data: videoUploadData,
    loading,
    error,
  } = useVideoUploadByIdPublicQuery({
    variables: {
      videoId: userVideoId,
    },
  });

  const userVideoData = videoUploadData?.videoUploadByIdPublic;

  if (error) {
    return <Redirect to="/not-found" />;
  }

  if (loading || !userVideoData) {
    return <></>;
  }

  return (
    <UserVideoPost
      playOnLoad
      userVideoData={userVideoData}
      fromModule={fromModule}
    />
  );
}
