import React from "react";
import { useParams } from "react-router";
import { ANALYTICS_MODULE } from "constants/analytics";
import Flex from "app/components/Flex";
import { VideoPost } from "./VideoPost";

const UserPost = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <Flex width="100%" position="relative" mt={{ _: 0, lg: 4, xl: 5 }}>
      <VideoPost
        userVideoId={id}
        fromModule={ANALYTICS_MODULE.user_video_post}
      />
    </Flex>
  );
};

export default UserPost;
